.MuiButton-root {
    text-transform: none!important;
}
.data-loader {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    .info {
        background-color: #fff;
        box-shadow: 0 0 20px rgba($color: #000, $alpha: 0.2);
        padding: 10px 24px;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        color: rgb(100, 100, 100);
    }
    &.shown {
        top: 0;
        transition: .5s top ease;
    }
    &.hidden {
        top: -60px;
        transition: .5s top ease;
    }
}